import React from 'react';
import Header from '../Shared/Components/Header/Header';
import Hero from '../../Assets/Images/RetailProgrammes/specialist.jpg';
import HeroMob from '../../Assets/Images/RetailProgrammes/specialist.jpg';
import LineText from '../Shared/Components/LineText/LineText';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Button from '../Shared/Components/Button/Button';
import StoreFront from '../../Assets/Images/RetailProgrammes/StoreFront.jpg';
import TextImageRow from '../Shared/Components/TextImageRow/TextImageRow';
import VisMerch from '../../Assets/Images/RetailProgrammes/VisMerch.jpg';
import LineIcon from 'react-lineicons';

const SpecialistRetailProgrammes = () => {
    return (
        <>
            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="Exclusive"
                title="Specialist Retail Programmes"
                subtitle="Elevate Your Business"
            />

            <TextImageRow
                lineText="Retail specialists"
                title="Elevate Your Business with Our New Exclusive Retail Programmes"
                paragraph1="At academyONE, we are dedicated to helping businesses of all sizes thrive by equipping teams with the skills, knowledge, and strategies needed to excel in today’s competitive landscape. We’re thrilled to introduce two groundbreaking programmes designed to revolutionise your approach to retail sales and visual merchandising… sustainably."
                paragraph2="Inspired by The Retail Start-Up Book, these programmes offer unparalleled insights and actionable strategies that will empower your retail business to succeed, no matter your size or market position."
                rowReverse={false}
                animate={true}
                image={StoreFront}
                buttonText={"Retail Start-Up Book"}
                buttonUrl="https://grey4gold.com/the-book/"
            />


            <TextImageRow
                lineText="Visual Merchandising"
                title="Improving Sales Through the Art of Visual Merchandising"
                paragraph1="Visual merchandising is more than just arranging products on a shelf; it’s about creating an experience that captures the customer’s attention, tells a compelling story, creates theatre and drives sales. In this programme we dive deep into the art and science of visual merchandising, making these strategies accessible and practical for retailers of any size, whilst also considering the environment."
                image={VisMerch}
                rowReverse={true}
                animate={true}
            >
                <div className="ml-4">
                    <div className=" text-center text-md-left pt-3">
                        <div className="">
                            <h6>Understanding the Customer Journey</h6>
                            <p>Design spaces that guide customers through a seamless shopping experience.</p>
                            <hr />
                        </div>
                        <div className="">
                            <h6>Maximising Store Layout</h6>
                            <p>Strategically position products to enhance visibility and sales potential.</p>
                            <hr />
                        </div>
                        <div className="">
                            <h6>Seasonal and Thematic Displays</h6>
                            <p>Craft displays that resonate with your brand and captivate your audience.</p>
                            <hr />
                        </div>
                        <div className="">
                            <h6>Data-Driven Merchandising</h6>
                            <p>Leverage data analytics to improve merchandising strategies.</p>
                            <hr />
                        </div>
                    </div>
                </div>
            </TextImageRow>

            <section className={`row no-gutters text-image`}>
                <div className="container-xxl">
                    <div className="container-ac">
                        <div className="col-12">
                            <div className="text-center">
                                <LineText lineText={"Sustainable sales"} center={true} />
                                <h2>Improving Sales Sustainably</h2>
                                <p>
                                    The Improving Sales Sustainably course is designed to help retailers integrate sustainable practices into their sales strategies, aligning with modern consumer values and environmental standards. This programme equips businesses with actionable insights to reduce their environmental impact while driving sales growth.
                                </p>
                                <p>
                                    By the end of the "Improving Sales Sustainably" course, you will be equipped with the knowledge and tools to not only boost sales but also contribute positively to the environment, making your business a leader in sustainable retail practices.
                                </p>
                            </div>
                        </div>

                        <div className="col-12">
                            <h2 className="text-center mt-4">Key Highlights</h2>
                        </div>

                        <div className="row">
                            {/* First Column */}
                            <div className="col-12 col-md-6">
                                <div className="mb-4 text-center">
                                    <LineIcon name="star-empty" alt="Key Highlight" style={{ color: "#f38621", marginBottom: "1rem" }} />
                                    <h6>Sustainable Sales Strategies</h6>
                                    <p>Prioritize sustainability while boosting revenue.</p>
                                </div>
                                <div className="mb-4 text-center">
                                    <LineIcon name="star-empty" alt="Key Highlight" style={{ color: "#f38621", marginBottom: "1rem" }} />
                                    <h6>Product Positioning</h6>
                                    <p>Market and merchandise products to attract consumers.</p>
                                </div>
                                <div className="mb-4 text-center">
                                    <LineIcon name="star-empty" alt="Key Highlight" style={{ color: "#f38621", marginBottom: "1rem" }} />
                                    <h6>Minimising Environmental Impact</h6>
                                    <p>Reduce your business’s carbon footprint.</p>
                                </div>
                                <div className="mb-4 text-center">
                                    <LineIcon name="star-empty" alt="Key Highlight" style={{ color: "#f38621", marginBottom: "1rem" }} />
                                    <h6>Engaging Consumers</h6>
                                    <p>Build trust and credibility with your target audience.</p>
                                </div>
                            </div>

                            {/* Second Column */}
                            <div className="col-12 col-md-6">
                                <div className="mb-4 text-center">
                                    <LineIcon name="star-empty" alt="Key Highlight" style={{ color: "#f38621", marginBottom: "1rem" }} />
                                    <h6>Leveraging Technology for Sales</h6>
                                    <p>Explore digital tools that enhance efficiency.</p>
                                </div>
                                <div className="mb-4 text-center">
                                    <LineIcon name="star-empty" alt="Key Highlight" style={{ color: "#f38621", marginBottom: "1rem" }} />
                                    <h6>Adapting to Consumer Trends</h6>
                                    <p>Stay ahead of consumer trends and adapt to preferences.</p>
                                </div>
                                <div className="mb-4 text-center">
                                    <LineIcon name="star-empty" alt="Key Highlight" style={{ color: "#f38621", marginBottom: "1rem" }} />
                                    <h6>Building a Sales Culture</h6>
                                    <p>Foster a culture that promotes sustainable practices.</p>
                                </div>
                                <div className="mb-4 text-center">
                                    <LineIcon name="star-empty" alt="Key Highlight" style={{ color: "#f38621", marginBottom: "1rem" }} />
                                    <h6>Tracking and Measuring Success</h6>
                                    <p>Use KPIs to track sustainability and sales performance.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <Button buttonText={"Enquire now"} buttonUrl={"/contact-us"} center={true} divClass={"mt-3"} />
                        </div>
                    </div>
                </div>
            </section>




            {/* Delivery Methodology and Pricing */}
            <section className={`row no-gutters row-pad bgGrey`}>
                <div className="container-xxl">
                    <div className="col-12 col-md-8 offset-md-2 text-center">
                        <LineText lineText={"Delivery Methodology"} center={true} />
                        <h2 className='mt-3'> We Come To You! </h2>
                        <h5 className='mt-3'>You just pay for our travel, reducing the expense and environmental impact of sending your team to us.</h5>
                    </div>
                    <div className="col-12 col-md-8 offset-md-2 text-center mt-3">
                        <p className="text-center"><strong className='orange'>Individual Pricing:</strong> £300 plus VAT per delegate (minimum 10 candidates).</p>
                        <p className="text-center"><strong className='orange'>Bundle Offer:</strong> Special pricing available for larger teams - contact us for details.</p>
                        <p className="mt-3 text-center">Take advantage of our bundle offer to upskill your team and drive sustainable growth across your business. Investing in sustainable sales strategies not only boosts your bottom line but also strengthens your brand’s commitment to a better future.</p>
                        <p className="text-center"><Link to="/contact-us">Contact us today</Link> to learn more about our pricing options and secure your place on the course</p>
                        <p className="text-center">All courses are certificated via our digital badging system and all delegates will receive ‘planet credits’ towards your sustainable retail journey.</p>
                        <Button buttonText={"Contact Us"} buttonUrl={"/contact-us"} center={true} divClass={"mt-3"} />
                    </div>
                </div>
            </section >

        </>
    );
};

export default SpecialistRetailProgrammes;
