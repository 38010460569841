import React from 'react';
import './MobileMenu.scss';
import Logo from '../../Assets/academy-one-rev.png';
import { Link } from 'react-router-dom';

const MobileMenu = (props) => {
    return (
        <div className={`mobMenu ${props.mobMenu ? "open" : "hidden"}`}>
            <div className="row no-gutters">
                <div className="logo">
                    <img className="img-fluid" width="400px" height="120px" src={Logo} alt="AcademyOne" />
                </div>
            </div>
            <div className="menu-links">
                <ol>
                    <li>
                        <Link to="/" onClick={() => props.toggleMenu()}>Home</Link>
                    </li>
                    <li>
                        <Link to="/about" onClick={() => props.toggleMenu()}>About</Link>
                    </li>
                    <li>
                        <Link to="/training-and-development" onClick={() => props.toggleMenu()}>Training &amp; Development</Link>
                    </li>
                    <li>
                        <Link to="/specialist-retail-programmes" onClick={() => props.toggleMenu()}>Specialist Retail Programmes</Link>
                    </li>
                    <li>
                        <Link to="/schools" onClick={() => props.toggleMenu()}>Schools</Link>
                    </li>
                    <li>
                        <Link to="/consultancy" onClick={() => props.toggleMenu()}>Consultancy</Link>
                    </li>
                    <li>
                        <Link to="/our-partners" onClick={() => props.toggleMenu()}>Our Partners</Link>
                    </li>
                    <li>
                        <Link to="/contact-us" onClick={() => props.toggleMenu()}>Contact Us</Link>
                    </li>
                    <li>
                        <a href={`https://elearning.${window.location.host.replace("www.", "")}`} onClick={() => props.toggleMenu()}>Login</a>
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default MobileMenu;